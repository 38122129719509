@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
}

button {
  all: unset;
}

/* scrollbar */
html {
  scrollbar-color: #010a0f #e5e5e5;
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 8px;
}

html::-webkit-scrollbar-thumb {
  background: #010a0f;
}

html::-webkit-scrollbar-thumb:hover {
  background: #010a0f;
}

html::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Change carousel arrows position on mobile */
@media only screen and (max-width: 768px) {
  div:has(> button[data-testid='carousel-left-control']),
  div:has(> button[data-testid='carousel-right-control']) {
    align-items: end;
  }

  button[data-testid='carousel-left-control'],
  button[data-testid='carousel-right-control'] {
    margin-bottom: 8px;
  }
}
